import React, { useState, useEffect } from 'react'

import { Drawer } from '@creditas/payroll-ui'
import { Global } from '@creditas/global'
import { standard, ThemeProvider } from '@creditas-ui/react'
import { ApolloProvider, useCreateApolloClient } from '@creditas/apollo'

import environment from './environment'
import { AppCustomProps } from '@src/hooks/useCustomProps'
import { CustomPropsProvider } from '@src/hooks/useCustomProps'
import { FormProvider } from './Drawer/context/FormProvider'
import { StepDefiner } from './Drawer/components/StepDefiner'

export function App(props: AppCustomProps) {
  const apolloClient = useCreateApolloClient({
    uri: environment.BFF_URI!,
    authorization: props.authToken,
  })
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)

  function closeDrawer() {
    setIsDrawerOpen(false)
    setTimeout(() => {
      props.unmountSelf(), 500
    })
  }

  useEffect(() => {
    return () => props.onClose()
  }, [])

  return (
    <CustomPropsProvider value={props}>
      <ThemeProvider theme={standard}>
        <ApolloProvider client={apolloClient}>
          <Global />
          <Drawer open={isDrawerOpen} rounded onClose={props.onClose}>
            <FormProvider>
              <StepDefiner onClose={closeDrawer} />
            </FormProvider>
          </Drawer>
        </ApolloProvider>
      </ThemeProvider>
    </CustomPropsProvider>
  )
}
