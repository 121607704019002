import { Box, Flex, styled, Typography } from '@creditas-ui/react'
import { useTranslation } from '@creditas/i18n'
import React from 'react'

interface Props {
  title: string
  index: number
  children: React.ReactChild
}

const Bullet = styled.div`
  background: ${({ theme }) => theme.colors.neutral[90]};
  width: 24px;
  height: 24px;
  border-radius: 24px;
  padding-left: 8px;
  padding-top: 2px;
  margin-right: 8px;
`
export const SectionWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[10]};
  margin-left: 32px;
`

export const NewAmountSection = ({ title, index, children }: Props) => {
  const { t } = useTranslation()

  return (
    <SectionWrapper py={4}>
      <Flex mb={4}>
        <Bullet>
          <Typography variant="bodySmBold" color="neutral.0">
            {index}
          </Typography>
        </Bullet>
        <Typography variant="headingXsMedium">{t(title)}</Typography>
      </Flex>
      {children}
    </SectionWrapper>
  )
}
