import React, { useEffect, useState } from 'react'
import { Box, Dropdown, TextArea, Typography } from '@creditas-ui/react'
import { useTranslation } from '@creditas/i18n'

export enum ReasonEnum {
  ATTENDANCE = 'attendance',
  VACATIONS = 'vacations',
  WRONG_MARGIN = 'wrongMargin',
  HAS_ANOTHER_LOAN = 'hasAnotherLoan',
  SHARE = 'share',
  OTHERS = 'others',
}

interface DropdownOption {
  value: string
  text: string
}
interface Props {
  updateReason: (reason: string, otherReason?: string) => void
  reason: string
  contractType: string
}

export const MarginExceededReason = ({
  updateReason,
  reason,
  contractType,
}: Props) => {
  const { t } = useTranslation()

  const [selectedReason, setSelectedReason] = useState<string>(reason)
  const [otherReason, setOtherReason] = useState<string>('')

  useEffect(() => {
    setSelectedReason(reason)
  }, [reason])

  const selectReason = (reason: string) => {
    setSelectedReason(reason)
    updateReason(reason)
  }

  const onChangeOtherReason = event => {
    const otherReason = event.target.value
    setOtherReason(otherReason)
    updateReason(selectedReason, otherReason)
  }

  const reasonOptions: DropdownOption[] = [
    {
      value: ReasonEnum.ATTENDANCE,
      text: t(`newAmountRegister.reasonOptions.${ReasonEnum.ATTENDANCE}`),
    },
    {
      value: ReasonEnum.VACATIONS,
      text: t(`newAmountRegister.reasonOptions.${ReasonEnum.VACATIONS}`),
    },
    {
      value: ReasonEnum.WRONG_MARGIN,
      text: t(`newAmountRegister.reasonOptions.${ReasonEnum.WRONG_MARGIN}`),
    },
    {
      value: ReasonEnum.HAS_ANOTHER_LOAN,
      text: t(`newAmountRegister.reasonOptions.${ReasonEnum.HAS_ANOTHER_LOAN}`),
    },
    {
      value: ReasonEnum.SHARE,
      text: t(`newAmountRegister.reasonOptions.${ReasonEnum.SHARE}`),
    },
    {
      value: ReasonEnum.OTHERS,
      text: t(`newAmountRegister.reasonOptions.${ReasonEnum.OTHERS}`),
    },
  ]
  return (
    <>
      <Dropdown
        id="dropdown"
        data-testid="dropdown"
        options={reasonOptions}
        text={t('newAmountRegister.reasonOptions.selectLabel')}
        value={selectedReason}
        onChangeOption={({ text }) => selectReason(text)}
        disabled={contractType === 'SALARY_ADVANCE' ? true : false}
      />
      <Typography mt={2} variant="bodyXsRegular" color="neutral.80">
        {t('newAmountRegister.mandatoryField')}
      </Typography>
      {selectedReason === 'Outros' && (
        <Box mt={4}>
          <TextArea
            label={t('newAmountRegister.reasonOptions.otherReasonLabel')}
            enterKeyHint={undefined}
            onChange={onChangeOtherReason}
          />
          <Typography mt={2} variant="bodyXsRegular" color="neutral.80">
            {t('newAmountRegister.mandatoryField')}
          </Typography>
        </Box>
      )}
    </>
  )
}
