// Single SPA production entry file
import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'

import { App } from './App'
import environment from './environment'
import * as NewRelic from '@src/utils/newRelic'
import { AppCustomProps } from '@src/hooks/useCustomProps'
import { initReactI18N } from '@src/utils/translations/initReactI18N'

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  domElementGetter() {
    return document.querySelector(`[data-app="${environment.appName}"]`)!
  },
})

export const mount = [reactLifecycles.mount]

export const unmount = [reactLifecycles.unmount]

export const bootstrap = [
  reactLifecycles.bootstrap,
  async function(props: AppCustomProps) {
    await initReactI18N(props.language)
  },
  () => NewRelic.createTracer(environment.appName, 'bootstrap'),
]
