import { Flex, styled } from '@creditas-ui/react'

interface AlertCardProps {
  variant: 'warning' | 'danger' | 'success' | 'info'
}

export const AlertCardContainer = styled(Flex)<AlertCardProps>`
  align-items: center;
  gap: 16px;
  padding: 24px 32px;
  background-color: ${({ variant }) =>
    variant === 'warning' ? `rgba(180, 97, 0, 0.08)` : '#FFF'};
`
