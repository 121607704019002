import React from 'react'
import { RemainingBalance } from './RemainingBalance'
import { useTranslation } from '@creditas/i18n'
import { formatCurrency } from '@src/utils/formatCurrency'

interface Props {
  payrollDiscount: number | undefined
  employeeCharge: number
}

export const Summary = ({ payrollDiscount, employeeCharge }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <RemainingBalance
        mt={0}
        text={t('newAmountRegister.summaryDatails.payrollDiscountLabel')}
        value={
          payrollDiscount !== undefined
            ? formatCurrency(payrollDiscount)
            : 'R$ -,--'
        }
        data-testid="payrollDiscountLabel"
      />
      <RemainingBalance
        mt={0}
        text={t('newAmountRegister.summaryDatails.employeeChargeLabel')}
        value={formatCurrency(employeeCharge)}
        data-testid="employeeChargeLabel"
      />
    </>
  )
}
