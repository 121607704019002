import React, { useContext } from 'react'

import { useMachine } from '@xstate/react'
import { State } from 'xstate'
import {
  MarginExceededFormContext,
  MarginExceededEvents,
  MarginExceededState,
  marginExceededMachine,
} from '../FormMachine'

interface Props {
  values: MarginExceededFormContext
  children: React.ReactChild
  incrementIndex: Function
}

interface ContextValues {
  context: State<
    MarginExceededFormContext,
    MarginExceededEvents,
    any,
    MarginExceededState
  >
  send: (
    event: MarginExceededEvents['type'] | MarginExceededEvents,
  ) => State<
    MarginExceededFormContext,
    MarginExceededEvents,
    any,
    MarginExceededState
  >
  incrementIndex: Function
}

const FormContext = React.createContext({} as ContextValues)

export const useFormContext = () => useContext(FormContext)

export const FormProviderWithStateMachine = ({
  values,
  children,
  incrementIndex,
}: Props) => {
  const [context, send] = useMachine(() => marginExceededMachine(values))
  return (
    <FormContext.Provider value={{ context, send, incrementIndex }}>
      {children}
    </FormContext.Provider>
  )
}
