import React from 'react'
import { ButtonPrimaryTextOnly, css, Flex, styled } from '@creditas-ui/react'
import { useTranslation } from '@creditas/i18n'

interface Props {
  disabled?: boolean
  isConfirmOpen: boolean
  isLoading: boolean
  onConfirm: () => void
}

interface StyledProps {
  isConfirmOpen: boolean
}

const StyleButton = styled(ButtonPrimaryTextOnly)<StyledProps>`
  -webkit-transition: width 1s;
  -moz-transition: width 1s;
  -ms-transition: width 1s;
  -o-transition: width 1s;
  transition: width 1s;
  width: 100vw;
  ${({ isConfirmOpen }) =>
    isConfirmOpen &&
    css`
      width: 50vw;
    `}
`

export const FinishButton = ({
  disabled = false,
  onConfirm,
  isConfirmOpen,
  isLoading = false,
}: Props) => {
  const { t } = useTranslation()

  return (
    <StyleButton
      loading={isLoading}
      isConfirmOpen={isConfirmOpen}
      disabled={disabled}
      size="large"
      width="fixed"
      onClick={onConfirm}
    >
      {t('confirm')}
    </StyleButton>
  )
}
