import { i18n, initReactI18next } from '@creditas/i18n'

export async function initReactI18N(language: string) {
  const translations = require(`./${language}.json`)
  const resources = {
    [language]: {
      common: translations,
    },
  }
  return i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      ns: ['common'],
      defaultNS: 'common',
      lng: language,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    })
}
