export const formatCurrency = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
}).format

export const stringToFloat = (value: string): number =>
  Number(
    value
      .trim()
      .replace(/[R$.]+/g, '')
      .replace(',', '.'),
  )
