import React from 'react'
import { Typography } from '@creditas-ui/react'
import { AlertCardContainer } from './AlertCard.styles'

interface AlertCardProps {
  Icon: JSX.Element
  text: string
  variant: 'warning' | 'danger' | 'success' | 'info'
}

export function AlertCard({ text, Icon, variant }: AlertCardProps) {
  return (
    <AlertCardContainer data-testid="alert-card-component" variant={variant}>
      <div>{Icon}</div>
      <Typography
        variant={'bodyMdRegular'}
        children={text}
        color="neutral.100"
      />
    </AlertCardContainer>
  )
}
