import { InputCurrency, Typography } from '@creditas-ui/react'
import { standard } from '@creditas-ui/react'
import { useTranslation } from '@creditas/i18n'
import { useCustomProps } from '@src/hooks'
import React, { useState } from 'react'

interface Props {
  updateNewAmount: (amount: number | undefined) => void
  maxAmount: number
  selectedAmount: number | undefined
  contractType: string
  canEqualToMaxAmount: boolean
}

const { colors } = standard
const colorError = colors.error['60']

export const NewAmount = ({
  updateNewAmount,
  maxAmount,
  selectedAmount,
  contractType,
  canEqualToMaxAmount,
}: Props) => {
  const { t } = useTranslation()
  const {} = useCustomProps()
  const [value, setValue] = useState(selectedAmount)
  const isSalaryAdvanceContract = contractType === 'SALARY_ADVANCE'

  const handleChange = currentValue => {
    const defaultRule = currentValue > maxAmount - 0.01
    const isExceededMaxAmount = currentValue > maxAmount

    if (
      (!canEqualToMaxAmount && defaultRule) ||
      (canEqualToMaxAmount && isExceededMaxAmount)
    ) {
      setValue(undefined)
      updateNewAmount(undefined)
      return
    }

    const hasValue = currentValue !== undefined
    const isGreaterThanZero = currentValue >= 0
    const newValue = hasValue && isGreaterThanZero ? currentValue : value

    setValue(newValue)
    updateNewAmount(newValue >= 0 ? newValue : undefined)
  }

  return (
    <>
      <InputCurrency
        label={t('newAmountRegister.newAmount.title')}
        style={{ color: value === undefined ? colorError : '' }}
        onChange={(_, rawValue) => handleChange(rawValue)}
        data-testid="newAmountInput"
        prefix="R$"
        value={isSalaryAdvanceContract ? maxAmount.toString() : '-'}
        enterKeyHint={undefined}
        disabled={isSalaryAdvanceContract}
      />
      <Typography mt={2} variant="bodyXsRegular" color="neutral.80">
        {t('newAmountRegister.mandatoryField')}
      </Typography>
    </>
  )
}
