import React from 'react'
import { useFormContext } from '../../context/FormProviderWithStateMachine'
import { NewAmountRegister } from '../../screens'

export function StepDefiner({ onClose }: { onClose: () => void }) {
  const { context } = useFormContext()
  const { matches } = context

  if (matches('newAmountRegister')) {
    return <NewAmountRegister onClose={onClose} />
  }

  return null
}
