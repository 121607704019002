import React from 'react'
import { createContext, useContext } from 'react'

export const defaultCustomProps = {
  language: 'pt-BR',
  userType: 'default',
  productBasePath: '',
  authToken: sessionStorage.authToken || '',
  deductions: [
    {
      clientName: '',
      installmentAmount: '',
      installmentNumber: 0,
      numberOfInstallments: 0,
      contractType: '',
      dueDate: '',
      conciliatorId: '',
      id: '',
      contractExternalId: '1',
      contractDisabledToMarginExceeded: false,
    },
  ],
  open: true,
  onClose: () => {},
  errorBoundary(err, info, props) {
    // https://reactjs.org/docs/error-boundaries.html
    return <div>This renders when a catastrophic error occurs</div>
  },
  singleSpa: {},
  domElement: document.createElement('div'),
  mountParcel() {},
  unmountSelf() {},
}

export type AppCustomProps = typeof defaultCustomProps

export const CustomPropsContext = createContext<AppCustomProps>(
  defaultCustomProps,
)

export const CustomPropsProvider = CustomPropsContext.Provider

export const useCustomProps = (): AppCustomProps =>
  useContext(CustomPropsContext)
