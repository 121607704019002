import { Deduction } from '@src/types/Deduction'
import { createMachine, assign } from 'xstate'

type MarginExceededReason = {
  type: 'SET_MARGIN_EXCEEDED_REASON'
  value: string | undefined
}

type CurrentDeductionEvent = {
  type: 'SET_CURRENT_DEDUCTION'
  value: Deduction | undefined
}

type IncrementIndexEvent = {
  type: 'INCREMENT_INDEX'
}

type MarginExceededAmountEvent = {
  type: 'SET_MARGIN_EXCEEDED_AMOUNT'
  value: number | undefined
}

export type MarginExceededState =
  | {
      value: 'idle'
      context: MarginExceededFormContext
    }
  | { value: 'newAmountRegister'; context: MarginExceededFormContext }

export type MarginExceededEvents =
  | MarginExceededAmountEvent
  | MarginExceededReason
  | IncrementIndexEvent
  | CurrentDeductionEvent

export type MarginExceededFormContext = {
  currentDeduction: {
    clientName: string
    installmentAmount: string
    installmentNumber: number
    numberOfInstallments: number
    contractType: string
    dueDate: string
    conciliatorId: string
    id?: string
    totalAmount?: number
    reason?: string
    otherReason?: string
    contractExternalId: string
    contractDisabledToMarginExceeded: boolean
  }
  currentDeductionIndex: number
  deductionsLength: number
}

export const marginExceededMachine = (
  initialContext: MarginExceededFormContext,
) =>
  createMachine<MarginExceededFormContext, MarginExceededEvents>(
    {
      predictableActionArguments: true,
      context: initialContext,
      id: '(marginMachine)',
      initial: 'idle',
      states: {
        idle: {
          always: [
            {
              cond: 'unfilledReason',
              target: 'newAmountRegister',
            },
            {
              cond: 'unfilledAmount',
              target: 'newAmountRegister',
            },
          ],
        },
        newAmountRegister: {
          on: {
            SET_MARGIN_EXCEEDED_REASON: {
              actions: assign(({ currentDeduction }, { value }) => ({
                currentDeduction: {
                  ...currentDeduction,
                  reason: value,
                },
              })),
            },
            SET_MARGIN_EXCEEDED_AMOUNT: {
              actions: assign(({ currentDeduction }, { value }) => ({
                currentDeduction: {
                  ...currentDeduction,
                  totalAmount: value,
                },
              })),
            },
          },
        },
      },
      on: {
        SET_CURRENT_DEDUCTION: {
          actions: 'setCurrentDeduction',
        },
        INCREMENT_INDEX: {
          actions: 'incrementIndex',
        },
      },
    },
    {
      guards: {
        unfilledReason: ({ currentDeduction }) =>
          !currentDeduction.reason ||
          Boolean(currentDeduction.reason && !currentDeduction.otherReason),
        unfilledAmount: ({ currentDeduction }) =>
          !Boolean(currentDeduction.totalAmount),
      },
      actions: {
        setCurrentDeduction: assign((context, event) => {
          const { value } = event as CurrentDeductionEvent
          return {
            ...context,
            ...value,
          }
        }),
        incrementIndex: assign(({ currentDeductionIndex }) => ({
          currentDeductionIndex: currentDeductionIndex + 1,
        })),
      },
    },
  )
