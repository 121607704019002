import { warning } from './warning'

export function createTracer(moduleName: string, action?: string) {
  return new Promise<void>(resolve => {
    const newrelic = window.newrelic
    if (!newrelic) {
      warning('Your project doest not have a new relic loaded!')
      return resolve()
    }
    const tracer = newrelic
      .interaction()
      .createTracer(`${moduleName}${action ? `/${action}` : ''}`, () =>
        resolve(),
      )
    tracer()
  })
}
