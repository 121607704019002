import React from 'react'
import { Box, Flex, Typography, styled, Grid } from '@creditas-ui/react'
import { getMonthYear } from '@src/utils/transformDates'
import { SectionWrapper } from '../NewAmountSection/NewAmountSection'
import { Trans, useTranslation } from '@creditas/i18n'

interface Contract {
  contractType: string
  amount: string
  installmentNumber: number
  numberOfInstallments: number
}

interface Props {
  clientName: string
  cycleDate: string
  contractInfo: Contract[]
}

const DateStyle = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.neutral[10]};
  border-radius: 10px;
  padding: 6px 10px;
  display: inline-flex;
  text-transform: capitalize;
`
const StyledSpan = styled.span`
  margin-left: 4px;
`

export const ClientInfo = ({ clientName, cycleDate, contractInfo }: Props) => {
  const { t } = useTranslation()

  return (
    <SectionWrapper py={4}>
      <Typography variant="headingLgMedium">{clientName}</Typography>
      <DateStyle mt={3} mb={40}>
        <Typography variant="bodyMdRegular">
          {getMonthYear(cycleDate)}
        </Typography>
      </DateStyle>
      <Typography mb={1} variant="bodyMdBold" color="warning.60">
        {t(`newAmountRegister.clientInfo.title`)}
      </Typography>
      {contractInfo.map((contract, index) => (
        <Box key={index}>
          <Grid
            mt={0}
            gridRowGap={0}
            gridTemplateRows="1fr 1fr"
            gridTemplateColumns="auto 1fr"
          >
            <Typography variant="bodyMdRegular" color="neutral.60">
              {t(`contractType.${contract.contractType}`)}
              <StyledSpan>
                <Trans
                  i18nKey={'newAmountRegister.installmentProgress'}
                  values={{
                    paid: contract.installmentNumber,
                    remaining: contract.numberOfInstallments,
                  }}
                />
              </StyledSpan>
            </Typography>
            <Typography
              color="neutral.60"
              variant="bodyMdRegular"
              style={{ textAlign: 'right' }}
            >
              {contract.amount}
            </Typography>
          </Grid>
        </Box>
      ))}
    </SectionWrapper>
  )
}
