import React, { useEffect, useState } from 'react'
import { useCustomProps } from '@src/hooks'
import { FormProviderWithStateMachine } from './FormProviderWithStateMachine'
import { Deduction } from '@src/types/Deduction'
interface Props {
  children: React.ReactChild
}

export const FormProvider = ({ children }: Props) => {
  const [deductionIndex, setDeductionIndex] = useState(0)
  const { deductions } = useCustomProps()
  const [currentDeduction, setCurrentDeduction] = useState<
    Deduction | undefined
  >(deductions[deductionIndex])
  const {
    clientName,
    installmentAmount,
    installmentNumber,
    numberOfInstallments,
    contractType,
    dueDate,
    conciliatorId,
    contractExternalId,
    contractDisabledToMarginExceeded,
  } = deductions[deductionIndex]

  const incrementIndex = () => {
    setCurrentDeduction(undefined)
    setDeductionIndex(deductionIndex + 1)
  }

  useEffect(() => {
    setCurrentDeduction(deductions[deductionIndex])
  }, [deductionIndex])

  return (
    <FormProviderWithStateMachine
      values={{
        currentDeduction: {
          ...currentDeduction,
          clientName,
          installmentAmount,
          installmentNumber,
          numberOfInstallments,
          contractType,
          dueDate,
          conciliatorId,
          contractExternalId,
          contractDisabledToMarginExceeded,
        },
        currentDeductionIndex: deductionIndex,
        deductionsLength: deductions.length,
      }}
      incrementIndex={incrementIndex}
    >
      {children}
    </FormProviderWithStateMachine>
  )
}
