import {
  Box,
  Flex,
  IconWarning_2,
  Typography,
  styled,
  css,
} from '@creditas-ui/react'
import { useTranslation } from '@creditas/i18n'
import React from 'react'

interface Props {
  isConfirmed: boolean
  cancel: () => void
}

interface StyledProps {
  isConfirmed: boolean
}

const StyledMessage = styled(Box)<StyledProps>`
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 136px;
  border-radius: 16px 16px 0 0;
  height: 0px;
  -webkit-transition: height 1s;
  -moz-transition: height 1s;
  -ms-transition: height 1s;
  -o-transition: height 1s;
  transition: height 1s;
  ${({ isConfirmed }) =>
    isConfirmed &&
    css`
      height: 290px;
    `}
  background-color: ${({ theme }) => theme.colors.neutral[0]};
`

const StyledBackdrop = styled(Box)<StyledProps>`
  position: absolute;
  left: 16px;
  right: 16px;
  top: 16px;
  bottom: 0;
  border-radius: 16px 16px 0 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  -webkit-transition: display 1s;
  -moz-transition: display 1s;
  -ms-transition: display 1s;
  -o-transition: display 1s;
  transition: display 1s;
  ${({ isConfirmed }) =>
    isConfirmed &&
    css`
      display: block;
    `}
`

const StyledWarning = styled(Box)`
  border-radius: 16px 16px 0 0;
`

export const WarningMessage = ({ isConfirmed, cancel }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <StyledBackdrop isConfirmed={isConfirmed} onClick={cancel} />
      <StyledMessage isConfirmed={isConfirmed} alignItems="flex-end">
        <StyledWarning
          display={'flex'}
          alignItems={'center'}
          paddingTop={20}
          paddingBottom={20}
          backgroundColor={'#E3790014'}
          width={'100%'}
        >
          <Box paddingLeft={24} paddingRight={24}>
            <IconWarning_2 color="#E37900" size={20} />
          </Box>
          <Typography variant={'bodyMdRegular'}>
            {t('exceededMarginRemoval.alert')}
          </Typography>
        </StyledWarning>

        <Flex display={'flex'} flexDirection="column" padding={32}>
          <Typography variant={'bodyXlMedium'} color="neutral.90">
            {t('exceededMarginRemoval.contestedRemovalQuestion')}
          </Typography>
          <Typography variant={'bodyMdRegular'} paddingTop={24}>
            {t('exceededMarginRemoval.explanation')}
          </Typography>
        </Flex>
      </StyledMessage>
    </>
  )
}
