import React from 'react'
import { Grid, Typography } from '@creditas-ui/react'

type RemainingBalanceProps = {
  mt: number
  text: string
  value: string | undefined
}

export function RemainingBalance({ mt, text, value }: RemainingBalanceProps) {
  return (
    <Grid
      mt={mt}
      gridRowGap={0}
      gridTemplateRows="1fr 1fr"
      gridTemplateColumns="auto 1fr"
    >
      <Typography variant="bodyLgMedium" color="neutral.80">
        {text}
      </Typography>
      <Typography
        color="neutral.90"
        variant="bodyLgMedium"
        style={{ textAlign: 'right' }}
      >
        {value}
      </Typography>
    </Grid>
  )
}
