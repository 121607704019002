import {
  Box,
  ButtonSecondary,
  css,
  Flex,
  styled,
  Typography,
  useTheme,
} from '@creditas-ui/react'
import { useTranslation } from '@creditas/i18n'
import React, { useState } from 'react'
import { FinishButton } from './FinishButton/FinishButton'
import { WarningMessage } from './WarningMessage/WarningMessage'

interface Props {
  onCancel: () => void
  onConfirm: () => void
  setConfirmOpen: (value: boolean) => void
  isContinueDisabled: boolean
  isConfirmOpen: boolean
  isLoading: boolean
  hasError: boolean
}

interface StyledProps {
  isConfirmOpen: boolean
}

const StyledButtons = styled(Box)`
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 0;
  padding: 40px 32px;
  border-radius: 16px 16px 0 0;
`

const StyledWrapper = styled.div<StyledProps>`
  -webkit-transition: width 1s;
  -moz-transition: width 1s;
  -ms-transition: width 1s;
  -o-transition: width 1s;
  transition: width 1s;
  display: inline-block;
  text-align: center;
  width: 0;
  ${({ isConfirmOpen }) =>
    isConfirmOpen &&
    css`
      width: 50vw;
    `}
`
const StyleButtonSecondary = styled(ButtonSecondary)`
  display: inline;
`

export const RegisterBottomSection = ({
  onCancel,
  onConfirm,
  setConfirmOpen,
  isContinueDisabled,
  isLoading,
  isConfirmOpen,
  hasError,
}: Props) => {
  const { t } = useTranslation()
  const { colors } = useTheme()

  return (
    <>
      <WarningMessage
        isConfirmed={isConfirmOpen}
        cancel={() => setConfirmOpen(false)}
      />

      <StyledButtons backgroundColor={colors.neutral[0]} paddingTop={32}>
        <Box pb={16} textAlign="center">
          {hasError && (
            <Typography variant="exceptionSmBold" color="error.60">
              {t('hasError')}
            </Typography>
          )}
        </Box>
        <Flex flexDirection="row">
          <StyledWrapper isConfirmOpen={isConfirmOpen}>
            {isConfirmOpen && (
              <StyleButtonSecondary
                size="large"
                style={{ padding: '18px 24px' }}
                onClick={onCancel}
                width="fixed"
              >
                {t('cancel')}
              </StyleButtonSecondary>
            )}
          </StyledWrapper>

          <FinishButton
            onConfirm={onConfirm}
            disabled={isContinueDisabled}
            isConfirmOpen={isConfirmOpen}
            isLoading={isLoading}
          />
        </Flex>
      </StyledButtons>
    </>
  )
}
