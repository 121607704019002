import React from 'react'
import { DrawerCloseButton, DrawerHeader } from '@creditas/payroll-ui'
import { Grid, styled, Typography } from '@creditas-ui/react'
import { useTranslation } from '@creditas/i18n'

interface Props {
  deductionIndex: number
  deductionsLength: number
  onClose: () => void
}

const LeftColumn = styled.div`
  line-height: 36px;
`
const RightColumn = styled.div`
  text-align: right;
  text-align: -webkit-right;
`

const ContractLabel = styled(Typography)`
  text-transform: uppercase;
  line-height: 38px;
`

export const HeaderComponent = ({
  deductionIndex,
  deductionsLength,
  onClose,
}: Props) => {
  const { t } = useTranslation()

  return (
    <DrawerHeader rounded onClose={onClose}>
      <Grid
        width={'100%'}
        gridRowGap={0}
        gridTemplateRows="1fr"
        gridTemplateColumns="auto 1fr"
      >
        <LeftColumn>
          <ContractLabel variant="exceptionSmBold">
            {deductionIndex + 1} / {deductionsLength}{' '}
            {t(`${deductionsLength > 1 ? 'contractsLabel' : 'contractLabel'}`)}
          </ContractLabel>
        </LeftColumn>
        <RightColumn>
          <DrawerCloseButton onClose={onClose} />
        </RightColumn>
      </Grid>
    </DrawerHeader>
  )
}
