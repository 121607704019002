import { gql, useMutation } from '@creditas/apollo'

export interface SplitPayrollDeduction {
  reason: string
  type: string
  companyPartialDiscountAmount: number
  totalInstallmentAmount: number
  conciliatorId: string
  payrollDeductionId: string
}

interface SplitDeductionResponse {
  companyPartialDiscountAmount: number
  reason: string
  status: string
  totalInstallmentAmount: number
  type: string
}

interface Variables {
  args: SplitPayrollDeduction[]
}

const SPLIT_DEDUCTION_MUTATION = gql`
  mutation SplitPayrollDeductions($args: [SplitPayrollDeduction!]!) {
    splitPayrollDeductions(args: $args) {
      companyPartialDiscountAmount
      reason
      status
      totalInstallmentAmount
      type
    }
  }
`

export const useSplitDeductions = () =>
  useMutation<SplitDeductionResponse, Variables>(SPLIT_DEDUCTION_MUTATION)
