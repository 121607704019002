import React, { Fragment, useEffect, useRef, useState } from 'react'
import { DrawerBody } from '@creditas/payroll-ui'
import {
  Box,
  ButtonPrimaryTextOnly,
  Flex,
  IconWarning_2,
  Typography,
} from '@creditas-ui/react'
import { useTranslation } from '@creditas/i18n'
import { useFormContext } from '../../context/FormProviderWithStateMachine'
import { useCustomProps } from '@src/hooks'
import { Deduction } from '@src/types/Deduction'
import { ClientInfo } from '../../components/NewAmountRegister/ClientInfo/ClientInfo'
import { MarginExceededReason } from '../../components/NewAmountRegister/MarginExceededReason/MarginExceededReason'
import { NewAmount } from '../../components/NewAmountRegister/NewAmount/NewAmount'
import { Summary } from '../../components/NewAmountRegister/Summary/Summary'
import {
  NewAmountSection,
  SectionWrapper,
} from '../../components/NewAmountRegister/NewAmountSection/NewAmountSection'
import { HeaderComponent } from '../../components/HeaderComponent/HeaderComponent'
import { RegisterBottomSection } from '../../components/NewAmountRegister/RegisterBottomSection/RegisterBottomSection'
import { stringToFloat } from '@src/utils/formatCurrency'
import {
  SplitPayrollDeduction,
  useSplitDeductions,
} from '@src/services/mutations/useSplitDeductions'
import WarningSection from '../../components/NewAmountRegister/WarningSection/WarningSection'
import { AlertCard } from '../../../../components/AlertCard/AlertCard'
import { AlertCardContainer } from './NewAmountRegister.styles'

interface Props {
  onClose: () => void
}

interface Discounts {
  employeeCharge: number
  payrollDiscount: number | undefined
}

export const NewAmountRegister = ({ onClose }: Props) => {
  const { t } = useTranslation()
  const topRef = useRef<null | HTMLDivElement>(null)
  const {
    send,
    incrementIndex,
    context: {
      context: { currentDeductionIndex },
    },
  } = useFormContext()
  const [splitDeductions] = useSplitDeductions()

  const { deductions } = useCustomProps()
  const [currentDeduction, setCurrentDeduction] = useState<Deduction>(
    deductions[currentDeductionIndex],
  )
  const [newReason, setNewReason] = useState<string>()
  const [otherReason, setOtherReason] = useState<string>()
  const [newAmount, setNewAmount] = useState<number>()
  const [hasError, setHasError] = useState<boolean>(false)
  const [discounts, setDiscounts] = useState<Discounts>({
    employeeCharge: stringToFloat(currentDeduction.installmentAmount),
    payrollDiscount: undefined,
  })
  const [isContinueDisabled, setIsContinueDisabled] = useState<boolean>(true)
  const [isSendingData, setIsSendingData] = useState<boolean>(false)
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)
  const [selectedReason, setSelectedReason] = useState<string>('')
  const [selectedAmount, setSelectedAmount] = useState<number>()

  useEffect(() => {
    setCurrentDeduction(deductions[currentDeductionIndex])
  }, [currentDeductionIndex])

  useEffect(() => {
    const hasReason =
      !!newReason && newReason === 'Outros' ? !!otherReason : !!newReason
    const hasNewAmount = newAmount !== undefined
    setIsContinueDisabled(!hasReason || !hasNewAmount)
  }, [newReason, otherReason, newAmount])

  const onNewReason = (reason: string, otherReason?: string) => {
    setNewReason(reason)
    setOtherReason(otherReason)
    setSelectedReason(reason)
  }

  const isSalaryAdvance = currentDeduction.contractType === 'SALARY_ADVANCE'

  const onNewAmount = (amount: number | undefined) => {
    setNewAmount(amount)
    setSelectedAmount(amount)
  }

  useEffect(() => {
    const total = stringToFloat(currentDeduction.installmentAmount)
    if (isSalaryAdvance) {
      setIsContinueDisabled(!isSalaryAdvance)
      setDiscounts({
        employeeCharge: 0,
        payrollDiscount: total,
      })
      return
    }

    setDiscounts({
      employeeCharge: total - (newAmount ?? 0),
      payrollDiscount: newAmount,
    })
  }, [newAmount, isSalaryAdvance])

  useEffect(() => {
    onNewReason('', undefined)
    setSelectedReason('')
    if (!isSalaryAdvance) {
      setDiscounts({
        employeeCharge: stringToFloat(currentDeduction.installmentAmount),
        payrollDiscount: undefined,
      })
    }
  }, [currentDeduction])

  const onNext = () => {
    if (currentDeductionIndex + 1 < deductions.length) {
      send('INCREMENT_INDEX')
      incrementIndex()
    } else {
      onClose()
    }
  }

  const onConfirmSalaryAdvanceDiscount = isSalaryAdvance => {
    if (isSalaryAdvance) {
      setConfirmOpen(false)
      setNewAmount(0)
      setIsSendingData(false)
      onNewReason('', undefined)
      onNewAmount(0)
      onNext()
      topRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const onConfirm = () => {
    if (!confirmOpen) {
      setConfirmOpen(!confirmOpen)
    } else {
      const data: SplitPayrollDeduction = {
        reason: newReason ? newReason : '',
        type: 'EXCEEDED_MARGIN',
        companyPartialDiscountAmount: newAmount ? Number(newAmount) : 0,
        totalInstallmentAmount: stringToFloat(
          currentDeduction.installmentAmount,
        ),
        conciliatorId: currentDeduction ? currentDeduction.conciliatorId : '',
        payrollDeductionId:
          currentDeduction && currentDeduction.id ? currentDeduction.id : '',
      }
      setHasError(false)
      setIsSendingData(true)
      setIsContinueDisabled(true)
      splitDeductions({
        variables: {
          args: [data],
        },
      })
        .then(data => {
          setConfirmOpen(false)
          setNewReason(undefined)
          setOtherReason(undefined)
          onNewAmount(undefined)
          setIsSendingData(false)
          onNext()
          topRef.current?.scrollIntoView({ behavior: 'smooth' })
        })
        .catch(error => {
          setHasError(true)
          setIsSendingData(false)
          setIsContinueDisabled(false)
        })
    }
  }
  return (
    <Fragment>
      <HeaderComponent
        deductionIndex={currentDeductionIndex}
        deductionsLength={deductions.length}
        onClose={onClose}
      />
      <DrawerBody>
        <Flex flexDirection="column" pb={32}>
          <SectionWrapper marginRight={32}>
            <Typography variant="headingLgLight" color="neutral.90">
              {isSalaryAdvance
                ? t('newAmountRegister.salaryAdvanceTitle')
                : t('newAmountRegister.title')}
            </Typography>

            {isSalaryAdvance ? (
              <Flex alignItems={'center'}>
                <IconWarning_2 color="#E37900" size={40} strokeWidth={0.5} />
                <Typography
                  variant={'bodyMdRegular'}
                  color={'neutral.60'}
                  mb={40}
                  mt={40}
                  ml={16}
                >
                  {t('newAmountRegister.salaryAdvanceSubtitle')}
                </Typography>
              </Flex>
            ) : (
              <Typography
                pt={3}
                pb={40}
                variant="bodyMdRegular"
                color="neutral.60"
              >
                {t('newAmountRegister.subtitle')}
              </Typography>
            )}
          </SectionWrapper>

          <Box overflowY="auto" paddingRight={22} paddingBottom={80}>
            <div ref={topRef}></div>
            <ClientInfo
              clientName={currentDeduction.clientName}
              cycleDate={currentDeduction.dueDate}
              contractInfo={[
                {
                  contractType: currentDeduction.contractType,
                  amount: currentDeduction.installmentAmount,
                  installmentNumber: currentDeduction.installmentNumber,
                  numberOfInstallments: currentDeduction.numberOfInstallments,
                },
              ]}
            />

            <NewAmountSection title="newAmountRegister.reasonTitle" index={1}>
              <MarginExceededReason
                updateReason={onNewReason}
                reason={selectedReason}
                contractType={currentDeduction.contractType}
              />
            </NewAmountSection>

            <NewAmountSection title="newAmountRegister.amountTitle" index={2}>
              <NewAmount
                updateNewAmount={onNewAmount}
                maxAmount={stringToFloat(currentDeduction.installmentAmount)}
                selectedAmount={selectedAmount}
                contractType={currentDeduction.contractType}
                canEqualToMaxAmount={
                  currentDeduction.contractDisabledToMarginExceeded
                }
              />
            </NewAmountSection>

            <NewAmountSection title="newAmountRegister.summaryTitle" index={3}>
              <Summary
                employeeCharge={discounts.employeeCharge}
                payrollDiscount={discounts.payrollDiscount}
              />
            </NewAmountSection>
            {!isSalaryAdvance && (
              <AlertCardContainer>
                <AlertCard
                  text={t('newAmountRegister.warningSection.marginExplanation')}
                  variant="warning"
                  Icon={<IconWarning_2 size={24} color="#E37900" />}
                />
              </AlertCardContainer>
            )}
          </Box>
          {isSalaryAdvance ? (
            <Flex flex={1} style={{ padding: '18px 24px' }}>
              <ButtonPrimaryTextOnly
                size="large"
                width="fixed"
                onClick={onConfirmSalaryAdvanceDiscount}
              >
                {t('continue')}
              </ButtonPrimaryTextOnly>
            </Flex>
          ) : (
            <>
              <RegisterBottomSection
                onCancel={onClose}
                onConfirm={onConfirm}
                setConfirmOpen={setConfirmOpen}
                isContinueDisabled={isContinueDisabled}
                isLoading={isSendingData}
                isConfirmOpen={confirmOpen}
                hasError={hasError}
              />
            </>
          )}
        </Flex>
      </DrawerBody>
    </Fragment>
  )
}
